const questions = [
    {
        id: 'faq-1',
        title: 'What does a day at this retreat look like?',
        answer:
            'We would like you to unpack and set up your writing supplies on a desk with a chair that feels comfortable. As you know, writers are very specific about their workplace. Please pick out a mug that you like for coffee or tea throughout the day. We are encouraging people to turn off their cellphones at least during the day hours.\n' +
            '\tThere is a gorgeous nature preserve only a few minutes away for a quick morning hike- set beside the Willamette river. Walk up a block to your left, and you will see a beautiful garden with a drop dead view of the river, the bridges of Portland, and OHSU the local hospital. This is a quiet distinguished neighborhood similar to Martha’s Vineyard except without the tourists. The houses are cottage like and famous for their gorgeous gardens. We have stocked the fridge with lots of breakfast staples with coffee and tea available all day. The fruit here is lucious, please help yourself. \n' +
            '\tWe are hoping to create a balance between quiet creative time, reading, looking at art, having conversation in the kitchen, and dinners around the dining room table. There are many beautiful spots on this property to observe nature. We are a Certified Natural Habitat, meaning we have old growth trees and local fauna on the property.\n',
    },
    {
        id: 'faq-2',
        title: 'Who can apply to this program?\n',
        answer:
            'The Portland Arts Retreat is open to applicants of all backgrounds and levels of experience. \n' +
            '\tIf you love writing, but need a dedicated time away from home to indulge in your creative practices, we encourage you to apply. \n' +
            '\tTo help your application stand out, we would like to see a brief outline of your published works, accomplishments, and future projects. It would be beautiful to think of the next To Kill A Mockingbird being written on these premises. \n',
    },
    {
        id: 'faq-3',
        title: "What does this program hope to accomplish? ",
        answer:
            'Good food, good conversation, and a hospitable environment which will encourage creativity. We would love to have a reading series to interact with local bookstores and libraries like Powells books. \n' +
            '\tThe initial line up includes nature writers, scriptwriters, and photographers who have an international reputation but are beguiled by Oregon\'s splendid natural beauty. \n' +
            'Ideally the retreat would encourage creative types from Oregon and throughout the U.S. and abroad to work daily on their project of choice.\n',
    },
    {
        id: 'faq-4',
        title: "How will this benefit Oregonians?\n",
        answer:
            'Oregon is a proud and beautiful state with a few other reputable writer’s programs. Reed College’s “The Tin House” which has been in existence for ten years and has developed a solid reputation for fostering writers. This program is only open during the summer months whereas the Oregon arts retreat would be available October through March each year.' +
            '\tThere is enough room for four writers in the main house and two more in a smaller cottage on the same property.  By bringing creative people from other parts of the states and abroad it will enhance a city that already has a rich cultural life. Like many American cities, Portland was affected by Covid and has recently revitalized its downtown center after a lot of hard work by local officials. Bringing an arts retreat to Portland would enhance what is already a vital downtown arts scene.\n',
    },
    {
        id: 'faq-5',
        title: 'Who is the Director of this program?\n',
        answer:
            'The Director of the Portland Arts Retreat is Anne Sikora, PsyD. Anne had a psychotherapy practice in NYC for 30 years and raised two children in Brooklyn. \n Anne worked in publishing in her 20\'s. She worked with the editor of the Norton Anthology of African American Literature. She also worked with Adrienne Rich, Audre Lourde, and Rita Dove, and worked at Grove Press where she edited a David Mamet play basd on Chekov\'s The Cherry Orchard. \n She married a prominent literary agent and entertained many well known writers such as Jhumpa Lahiri, Geoff Dyer, Jeffrey Eugenides, Nam Le, and Steve Toltz. \n Her children are very interested in the arts and serve on the board of directors. Anne\'s long time partner, Chris Awalt, is a novelist and professional editor. He is on the board of directors along with Anne\'s children, Ashley and Lucy, who have an avid interst in reading and writing. '  ,
    },
    {
        id: 'faq-6',
        title: 'Why does Portland Arts Retreat need a Grant?\n',
        answer:
            'A grant is needed to preserve the historical beauty of this old house and its surrounding wildlife. With our non-profit status, the idea is to bring artists to the local community. The property is located directly across the street from Llewellyn Elementary School. The neighborhood has a reputation for recycling, and the house has a free library box so that folks in the neighborhood can have access to books. \n' +
            '\tIn order to attract international writers who have been published, it would be helpful to have stipends to facilitate the retreat process. The retreat also is looking for new young writers who are trailblazing the artistic and literary landscape. \n' +
            'We are inspired by a model set by a retreat in the UK called Hawthornden castle that implements a cell free zone to encourage writers to have the peace and quiet to get work done. \n',
    },
];

import {Link} from "react-router-dom";
import {useState} from 'react';
import {CSSTransition} from 'react-transition-group';

const FAQ = () => {
    const [selected, setSelected] = useState(null);
    const toggle = (index) => {
        setSelected(selected === index ? null : index);
    };

    return (
        <section className="py-2 sm:py-12">
            <div className="mx-auto w-full px-4 sm:px-6 lg:max-w-6xl ">
                <div className="grid gap-12">
                    <div>
                        <h2 className="text-3xl px-4 font-medium tracking-tight sm:text-4xl">
                            Frequently asked questions
                        </h2>
                        <p className='text-base px-4 pt-8 tracking-tight'>Some of the frequently asked questions to us. If you would like to know anything else, feel free to <Link to={'/contact'}><span className='underline'>contact us</span></Link> . </p>
                    </div>
                    <div className="">
                        <dl className="-mt-3">
                            {questions.map((question, index) => {
                                const isOpen = selected === index;
                                return (
                                    <div key={index}
                                         className={`rounded-3xl px-4 transition ${
                                             isOpen ? 'bg-primary-500/10': ''
                                         }`}
                                    >
                                        <dt
                                            className={`border-b text-lg transition ${
                                                isOpen
                                                    ? 'border-transparent'
                                                    : 'border-primary-900/10'
                                            }`}
                                        >
                                            <button
                                                type="button"
                                                className="group block w-full py-6 text-left transition focus-visible:outline-none"
                                                onClick={() => toggle(index)}
                                                aria-expanded={isOpen}
                                            >
                                                <div
                                                    className="group-focus-visible:outline-primary-950 dark:group-focus-visible:outline-primary-200 flex items-center justify-between rounded-3xl group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2">
                                                    <span className="font-medium">{question.title}</span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <svg
                                                            className={`text-primary-600 dark:text-primary-400 h-6 w-6 transform transition duration-200 ease-in-out ${
                                                                isOpen ? '-rotate-180' : 'rotate-0'
                                                            }`}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                          <path
                                                              d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </button>
                                        </dt>
                                        <CSSTransition
                                            in={isOpen}
                                            timeout={300}
                                            classNames="collapse"
                                            unmountOnExit
                                        >
                                            <dd className="pb-6 pr-6" id={`question-${index}`}>
                                                <p className="text-primary-950/70 dark:text-primary-200/70 text-base">
                                                    {question.answer}
                                                </p>
                                            </dd>
                                        </CSSTransition>
                                    </div>
                                );
                            })}
                        </dl>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
