import ImageScroller from "../components/imageScroller.jsx";
import {useEffect, useRef, useState} from "react";
import {LazyLoadImage} from 'react-lazy-load-image-component';
const IMAGES = [
    {org: '/images/Image00.webp', thumb: '/images/1.webp'},
    {org: '/images/Image01.webp', thumb: '/images/1.webp'},
    {org: '/images/Image02.webp', thumb: '/images/1.webp'},
    {org: '/images/Image03.webp', thumb: '/images/1.webp'},
    {org: '/images/Image06.webp', thumb: '/images/1.webp'},
    {org: '/images/Image07.webp', thumb: '/images/1.webp'},
    {org: '/images/Image08.webp', thumb: '/images/1.webp'},
    {org: '/images/Image09.webp', thumb: '/images/1.webp'},
    {org: '/images/Image10.webp', thumb: '/images/1.webp'},
    {org: '/images/Image11.webp', thumb: '/images/1.webp'},
    {org: '/images/Image12.webp', thumb: '/images/1.webp'},
    {org: '/images/Image13.webp', thumb: '/images/1.webp'},
    {org: '/images/Image14.webp', thumb: '/images/1.webp'},
    {org: '/images/Image15.webp', thumb: '/images/1.webp'},
    {org: '/images/Image16.webp', thumb: '/images/1.webp'},
    {org: '/images/Image17.webp', thumb: '/images/1.webp'},
    {org: '/images/Image18.webp', thumb: '/images/1.webp'},
    {org: '/images/Image19.webp', thumb: '/images/1.webp'},
    {org: '/images/Image21.webp', thumb: '/images/1.webp'},
    {org: '/images/Image22.webp', thumb: '/images/1.webp'},
    {org: '/images/Image23.webp', thumb: '/images/1.webp'},
    {org: '/images/Image24.webp', thumb: '/images/1.webp'},
    {org: '/images/Image25.webp', thumb: '/images/1.webp'},
    {org: '/images/Image26.webp', thumb: '/images/1.webp'},
    {org: '/images/Image27.webp', thumb: '/images/1.webp'},
    {org: '/images/Image28.webp', thumb: '/images/1.webp'},
    {org: '/images/Image29.webp', thumb: '/images/1.webp'},
    {org: '/images/Image31.webp', thumb: '/images/1.webp'},
    {org: '/images/Image32.webp', thumb: '/images/1.webp'},
    {org: '/images/Image33.webp', thumb: '/images/1.webp'},
    {org: '/images/Image34.webp', thumb: '/images/1.webp'},
    {org: '/images/Image35.webp', thumb: '/images/1.webp'},
    {org: '/images/Image36.webp', thumb: '/images/1.webp'},
    {org: '/images/Image37.webp', thumb: '/images/1.webp'},
    {org: '/images/Image38.webp', thumb: '/images/1.webp'},
    {org: '/images/Image39.webp', thumb: '/images/1.webp'},
    {org: '/images/Image41.webp', thumb: '/images/1.webp'},
    {org: '/images/Image42.webp', thumb: '/images/1.webp'},
    {org: '/images/Image43.webp', thumb: '/images/1.webp'},
    {org: '/images/Image46.webp', thumb: '/images/1.webp'},
    {org: '/images/Image47.webp', thumb: '/images/1.webp'},
    {org: '/images/Image48.webp', thumb: '/images/1.webp'},
    {org: '/images/Image49.webp', thumb: '/images/1.webp'},
    {org: '/images/Image50.webp', thumb: '/images/1.webp'},
    {org: '/images/Image51.webp', thumb: '/images/1.webp'},
    {org: '/images/Image52.webp', thumb: '/images/1.webp'},
    {org: '/images/Image53.webp', thumb: '/images/1.webp'},
    {org: '/images/Image54.webp', thumb: '/images/1.webp'},
    {org: '/images/Image55.webp', thumb: '/images/1.webp'},
    {org: '/images/Image56.webp', thumb: '/images/1.webp'},
    {org: '/images/Image57.webp', thumb: '/images/1.webp'},
    {org: '/images/Image58.webp', thumb: '/images/1.webp'},
]

export default function Gallery() {
    const [open,setOpen] = useState(false)
    const [index,setIndex] = useState(0)
    const [images,setImages] = useState([])
    let ref = useRef(null)

    useEffect(() => {
        ref.current = setTimeout(() => {
            setImages(IMAGES)
        }, 600)
        return () => clearTimeout(ref.current)
    }, []);
    return <section className="py-16 sm:py-20">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="flex flex-col gap-16">
                <h1 className="text-4xl text-center font-medium tracking-tight sm:text-5xl lg:text-6xl">Gallery</h1>
                <p className="text-primary-950/70 mx-auto max-w-4xl text-center text-lg sm:text-xl">
                    What follows is a floating gallery of photographs from the Portland Arts Retreat. There are photos of original art pieces which are displayed throughout the property. We also captured many pictures of the historic house designed by Portland architect Wade Hampton Pipes In 1912 and the guest house at the back of the property.
                    The property itself has been designated a wildlife habitat, this is reflected in the photos of wildlife taken from our backyard. There are many locations for quiet reflection and creativity: a large back deck, an old-fashioned pergola and a front yard with ample seating. Soon to come are testimonies and photos of writers who have attended the retreat.
                </p>
                <div className="columns-2 cursor-pointer sm:columns-3 md:columns-4 lg:columns-5 gap-4 space-y-4">
                    {images.length ? images.map((image, i) =>
                        <div key={i} onClick={(e) => {
                            e.preventDefault()
                            setIndex(i)
                            setOpen(true)
                        }}>
                            <LazyLoadImage className="w-full rounded-xl " src={image.org}
                                 alt="Image 01"/>
                       </div>
                    ):'Loading...'}
                </div>
                {open && <div className={`fixed top-0 left-0 w-full h-full p-8`}>
                    <div className="opacity-95 fixed inset-0 z-40 bg-primary-950"></div>
                    <ImageScroller closeViewer={() => setOpen(false)} IMAGES={images} index={index}/>
                </div>}

            </div>
        </div>
    </section>
}
