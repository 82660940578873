import { useState } from "react";
import FAQ from "../components/faq.jsx";


const team = [
    {
        name: 'Anne Sikora, PsyD',
        desc: 'The Director of the Portland Arts Retreat is Anne Sikora, PsyD. Anne had a psychotherapy practice in NYC for 30 years and raised two children in Brooklyn. Anne worked in publishing in her 20s. She was a journalist writing an arts column for the Whig Standard, Canada’s oldest privately owned newspaper. She helped a well-respected editor, John Benedict, who established the Norton Anthology of African American Literature. The Anthology was edited by Henry Louis Gates, who brought marginalized literature into the forefront of the canon. She also helped edit works by Adrienne Rich, Audre Lorde, and Rita Dove, and worked at Grove Press where she edited a David Mamet play based on Chekhov\'s "The Cherry Orchard." She married a prominent literary agent and entertained many well-known writers such as Jhumpa Lahiri, Geoff Dyer, Jeffrey Eugenides, Nam Le, and Steve Toltz. Her children are very interested in the arts and serve on the board of directors. Anne\'s long-time partner, Chris Awalt, is a novelist and professional editor. He is on the board of directors along with Anne\'s children, Ashley and Lucy, who have an avid interest in reading and writing.',
        image: '/team/anne.webp',
        job: 'Executive Director/ President of the Board of Directors',
    },
    {
        name: 'Chris Awalt',
        desc: 'Chris is an editor and a novelist. He is also an avid photographer and a man of letters. He is Anne’s long-time partner and was raised in the same town that Friday Night Lights was written about. He is a jack of all trades and never finds a problem he can’t fix with excellence. He has two daughters, Bella and Olivia, and two stepdaughters, Ashley and Lucy.',
        image: '/team/chris.jpg',
        job: 'Co-Director/ Member of the Board of Directors',
    },
    {
        name: 'Lucy Simonoff',
        desc: 'Hello, My name is Lucy Simonoff and I am a student at the University of Edinburgh majoring in Sociology and Psychology. I have a range of experience freelance audio-editing podcasts, as well as content creating for non-profit organisations. I am also a book lover, and I look forward to discovering new literary voices and providing them with a place to flourish. My committee is responsible for reviewing potential writers’ work and selecting those that are the best fit for our workshop.',
        image: '/team/lucy.jpg',
        job: 'Member of the Board of Directors',
    },
    {
        name: 'Ashley',
        desc: 'Ashley is a passionate advocate for the arts. She loves discovering new literary voices. She is actively involved in reviewing manuscripts and contributing to the mission of the retreat.',
        image: '/team/ashley.jpeg',
        job: 'Member of the Board of Directors',
    },
    {
        name: 'Yulia Steshenko',
        desc: 'Yulia grew up in the Bronx, New York, in a rundown home packed with books. She developed MS at fourteen, became legally blind at sixteen, and stopped being able to read print shortly after, but maintained her love of reading through audiobooks. She graduated from Harvard College with a concentration in psychology. While homebound after college, she tried and failed to become a memoirist. With the help of the Commission for the Blind, she became a Certified Financial Planner to offer pro bono support to New Yorkers with disabilities and is now a Vice President on the board of the Center for Independence of the Disabled, New York. She still loves listening to and discussing books.',
        image: '/team/Yulia.jpeg',
        job: 'Vice President',
    },
    {
        name: 'Seth Fowler',
        desc: 'An experienced Customer and Client Services Representative, Founder and Former Director of FHS LGBTQ Alliance, Personal Assistant by day, Drag Queen by night.',
        image: '/team/seth.jpg',
        job: 'Director of Operations / Member of the Board of Directors',
    },
    {
        name: 'Kaiaka Ardana',
        desc: 'Kai is the house manager at West 13th Street. He has pretty much single-handedly renovated the property entirely, transforming it from an overgrown haunted house to its former splendor. He is a native Pacific Islander from the Big Island of Hawai’i and the father of two young kids. He is the oldest child in a warm Hawaiian family line.',
        image: '/team/kai.jpg',
        job: 'House Manager',
    },
    {
        name: 'Heavenly Hedenberg',
        desc: 'Heavenly is a long-time resident of Oregon, where she got her Associates Degree from Mount Hood Community College. She has an unbelievable skill for noticing details in her work ethic and an eye for design. She also loves the outdoors, is an experienced barista, baker and gardener, and is studying to get her Real Estate License.',
        image: '/team/heavenly.jpg',
        job: 'Member of the Board of Directors',
    },
    {
        name: 'River Spencer',
        desc: 'Hello, my name is River K. Spencer and I\'m a student at Portland State University currently studying political science with an emphasis on conflict management and the American judicial system. I\'m passionate about creative endeavors, and I love to see artist dreams realized with the fruition of their work. I want to be an advocate for those who want to work and are passionate about creative work. My committee oversees the obtaining of grants for the Writers Workshop so that faculty and pupils can best have their needs met. Thank you.',
        image: '/team/river.jpg',
        job: 'Member of the Board of Directors',
    },
];
function About() {
    const [selectedTeamMember, setSelectedTeamMember] = useState(null);
    const [expandedTeamMember, setExpandedTeamMember] = useState(null);
    return <>
        <section className="py-16 sm:py-20">
            <div className="mx-auto max-w-2xl px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex flex-col gap-4 sm:gap-6">
                    <h1 className="text-4xl font-medium tracking-tight sm:text-5xl lg:text-6xl">About Us</h1>
                    <p className="text-primary-950/70 px-4 mx-auto max-w-2xl text-lg sm:text-xl">
                        The Portland Arts Retreat was founded to support writers and visual artists, familiarize them
                        with the Oregon area, and to provide a sanctuary which encourages the creative process through
                        peace and quiet, clean air, clean water, and close proximity to nature and wildlife. The retreat is
                        located on a historic property and was designed by Portland architect Wade Hampton Pipes In
                        1912.
                    </p>
                </div>
            </div>
        </section>

        <section className="py-8 sm:py-20">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col gap-12 mb-16">
                    <h2 className="text-3xl font-medium tracking-tight text-center sm:text-4xl">Our History</h2>
                    <div
                        className="flex gap-y-8 gap-x-16 md:max-w-screen-md lg:max-w-screen-lg mx-auto flex-col md:flex-row">
                        <div className={'flex flex-col w-full items-center'}>
                            <img
                                 style={{backgroundSize:"100% 100%"}}
                                className="mx-auto max-w-[80%] md:mt-2 h-auto w-auto md:max-w-[100%] rounded-3xl"
                                src="/assets/history.webp"
                                alt=" Richard and Dorothy Sikora."
                            />
                            <p className={'pt-2'}> Richard and Dorothy Sikora</p>
                        </div>
                        <div className="space-y-6 px-4 text-justify mx-auto w-auto md:max-w-[50%]">
                            <p className="text-primary-900 text-base sm:text-lg">
                                The Portland Arts Retreat was inspired by art lovers Richard and Dorothy Sikora.
                                Richard was an Ethics professor at UBC in the 70’s and 80’s, and Dorothy was a
                                visual artist whose art was featured among notable female artists in Canada. The
                                couple owned a classical record store called Sikora’s Classical Records in
                                Vancouver
                                Canada that was beloved for over forty years. They moved to Portland because of
                                its
                                focus on the environment and its progressive political values.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                The Sikoras’ only daughter, Anne Sikora, had the idea of the arts retreat to
                                support
                                the creative community of Portland. Anne lived in NYC for thirty years where she
                                had
                                a psychotherapy practice. Her children Ashley and Lucy are on the board of
                                directors
                                reviewing potential manuscripts with other writers for the arts retreat. Their
                                father is a literary agent in NYC, so this is a family affair.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-12 ">
                    <h2 className="text-3xl font-medium tracking-tight text-center sm:text-4xl">Mission Statement</h2>
                    <div className="flex gap-y-8 gap-x-16 md:max-w-screen-md lg:max-w-screen-lg mx-auto flex-col md:flex-row-reverse">
                        <div className={'flex flex-col w-full items-center'}>
                            <img
                                className="mx-auto max-w-[80%] md:mt-2 h-auto w-auto md:max-w-[100%] rounded-3xl"
                                src="/assets/mission.webp"
                                alt="Richard and Dorothy Sikora."
                            />
                        </div>
                        <div className="space-y-6 px-4 text-justify mx-auto w-auto md:max-w-[50%]">
                            <p className="text-primary-900 text-base sm:text-lg">
                                The Portland Arts Retreat is meant to foster an environment where ideas can take
                                root and flourish.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                The retreat’s model is inspired by Hawthornden Castle, a well established retreat in
                                the UK. Hawthornden uses an application process to find the most qualified writers
                                who are serious about their craft.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                The idea is that folks are able to dream and imagine free from the pressures and
                                demands of daily life. We request that people put away their cellphones and find
                                other arrangements for childcare so that they can focus their attention on artistic
                                work that might not otherwise be possible.
                            </p>
                            <p className="text-primary-900 text-base sm:text-lg">
                                As it happens, there are many eyes on the board of directors reading and evaluating manuscripts including a disabled person, a Native American, a Pacific Islander, a transgender woman and many local Portland voices. A rich tapestry.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FAQ/>
        <section className="py-16 sm:py-20">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="sm:space-8 space-y-12">
                    <h2 className="text-3xl font-medium tracking-tight text-center sm:text-4xl">Meet our team</h2>
                    <ul role="list" className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
    {team.map((item, i) => (
        <li
            key={i}
            className="border-primary-900/10 rounded-3xl border px-6 py-6"
            onClick={() => setSelectedTeamMember(selectedTeamMember === i ? null : i)} // Toggles visibility
        >
            <div className="space-y-6 flex flex-col h-full cursor-pointer">
                {/* Team Member Photo */}
                <div
                    style={{ backgroundImage: `url(${item.image})` }}
                    className="flex bg-cover bg-center hover:shadow-none transition shadow-xl items-center h-svh max-h-[350px] rounded-2xl w-full"
                ></div>

                {/* Name and Title */}
                <div className="text-center">
                    <h3 className="text-2xl font-medium">{item.name}</h3>
                    <p className="text-primary-950/70 text-sm mt-4">
    {expandedTeamMember === i
        ? item.desc // Show full bio if expanded
        : `${item.desc.substring(0, 150)}...`} {/* Show first 150 characters */}
    <span
        onClick={() =>
            setExpandedTeamMember(expandedTeamMember === i ? null : i)
        }
        style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
    >
        {expandedTeamMember === i ? " Show less" : " Read more"}
    </span>
</p>

                    {/* Conditionally Render Bio */}
                    {selectedTeamMember === i && (
                        <p className="text-primary-950/70 text-sm mt-4">{item.desc}</p>
                    )}
                </div>
            </div>
        </li>
    ))}
</ul>
                </div>
            </div>
        </section>

    </>
}

export default About
