//  name
//     dob
//     dates of stay ( 2 weeks increment? ), CHOICES 1,2,3
//     school
//     bio with prev work, grants, prizes, awards et
//     proposed project/images of work

import ReCAPTCHA from "react-google-recaptcha";
import {useState} from "react";
import {  addWeeks,subYears, } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css"
const WAIVER_LINK = 'https://drive.google.com/file/d/1UymuVJbW9AtoqNSkZuFlK5aZzdSO0kig/view'
const PHOTO_WAIVER_LINK = 'https://drive.google.com/file/d/1cEziBZzn-KEh-emQT9NmrDCAz4_WJ7m1/view'
const FORM_KEY = import.meta.env.VITE_FORM
function Apply() {
    const [buttonText, setButtonText] = useState("Submit");
    const [formValid, setFormValid] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(true);
    const [preferences, setPreferences] = useState([
        { from: "", to: "", error: "" },
        { from: "", to: "", error: "" },
        { from: "", to: "", error: "" }
    ]);
    const [failCount,setFailCount] = useState(0)
    const [failText,setFailText] = useState('')

    const handleDateChange = (index, field, date) => {
        const newPreferences = [...preferences];
        newPreferences[index][field] = date;
        newPreferences[index].error = "";

        const fromDate = new Date(newPreferences[index].from);
        const toDate = new Date(newPreferences[index].to);
        const maxToDate = addWeeks(fromDate, 2);
        const today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];

        if (field === "from" && toDate) {
            if (fromDate < today) {
                newPreferences[index].error = '"From" date cannot be before today.';
                newPreferences[index].from = "";
            } else if (fromDate > toDate) {
                newPreferences[index].error = '"From" date cannot be after "To" date.';
                newPreferences[index].from = "";
            } else if (toDate > maxToDate) {
                newPreferences[index].error = '"To" date cannot be more than two weeks after "From" date.';
                newPreferences[index].to = "";
            }
        }

        if (field === "to" && toDate) {
            if (toDate < today) {
                newPreferences[index].error = '"To" date cannot be before today.';
                newPreferences[index].to = "";
            }
            if (toDate < fromDate) {
                newPreferences[index].error = '"To" date cannot be before "From" date.';
                newPreferences[index].to = "";
            } else if (toDate > maxToDate) {
                newPreferences[index].error = '"To" date cannot be more than two weeks after "From" date.';
                newPreferences[index].to = "";
            }
        }
        setPreferences(newPreferences);
    };

    const validateForm = (event) => {
        const form = event.target.closest("form");
        const formValid = form.checkValidity() && captchaVerified && !preferences.some(pref => pref.error);
        setFormValid(formValid);
    };

    const onCaptchaChange = (value) => {
        setCaptchaVerified(!!value);
        validateForm({ target: {} });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!formValid) return;
        setButtonText("Loading...");

        const formData = new FormData(event.target);
        formData.append("access_key", FORM_KEY);

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();
        if (data.success) {
            setButtonText("Thank you!");
            event.target.reset();
            setPreferences([
                { from: "", to: "", error: "" },
                { from: "", to: "", error: "" },
                { from: "", to: "", error: "" }
            ]);
        } else {
            if(failCount === 0){
                alert("Something went wrong. Please try again!");
                setFailCount(failCount+1)
            }
            if(failCount===1){
                setFailText("Something went wrong. Please try again! " +
                    "If this issue persists, please email us at sikorasretreat@gmail.com")
            }
            setButtonText("Submit");
        }
    };

    return <div>
        <section className="py-16 sm:py-20">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-screen-lg lg:px-8">
                <div className="grid gap-12 lg:grid-cols-1 lg:gap-8">
                    <div className="flex flex-col gap-4 sm:gap-6">
                        <h1 id={'apply'} className="text-4xl text-center font-medium tracking-tight sm:text-5xl lg:text-6xl">Apply</h1>
                        <p className='text-primary-950 tracking-normal py-8 text-lg sm:text-xl text-justify'>
                             We welcome you to apply with an idea for a specific project you would
                            like to work on during the retreat. There are many beautiful spots on this
                            property to observe nature as the yard is abutting the Oaks Bottom Wildlife Refuge.
                            <br/>
                            Our program has been lucky to host some well-regarded artists and writers who have strong
                            reputations in their fields. We are also interested in fostering budding creative types with
                            projects they have been dreaming about. Each application is carefully reviewed by members of
                            the Portland Arts Retreat’s board of directors to determine which candidates would be most
                            suitable for the retreat.
                            <br/>
                            If your application is accepted, you will be contacted and transportation arrangements will
                            be provided. You will be picked up from the airport by our staff and given a tour of the
                            house upon arrival. Our local chef will prepare you a welcome meal as well as stock the
                            kitchen with groceries for breakfast and lunch.
                            <br/>
                            If you have any questions about the application process, email us at
                            sikorasretreat@gmail.com.
                        </p>
                    </div>

                    <form onChange={validateForm} onSubmit={onSubmit} className="mt-3 max-w-screen-lg m-auto w-full flex flex-col gap-y-6">
                        <input type="hidden" name="subject" value="New Application for Portland Arts Retreat"/>
                        <input type="hidden" name="from_name" value="Portland Arts Retreat"/>
                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="full-name" className="col-span-12 md:col-span-3 text-base ">Full
                                name</label>
                            <input
                                type="text"
                                name="Full Name"
                                id="fullName"
                                autoComplete="name"
                                required
                                className="col-span-12 md:col-span-9 gap-y-4 hover:ring-primary-600 focus:ring-primary-600  bg-primary-50 ring-primary-900/40 placeholder:text-primary-950/60  block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                            />
                        </div>
                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="email" className="col-span-12 md:col-span-3 text-base ">Email</label>
                            <input
                                type="email"
                                name="Email"
                                id="email"
                                required
                                autoComplete="email"
                                className="col-span-12 md:col-span-9 hover:ring-primary-600 focus:ring-primary-600  bg-primary-50     ring-primary-900/40 placeholder:text-primary-950/60    block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                            />
                        </div>


                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="dob" className="col-span-12 md:col-span-3 text-base ">Date of Birth</label>
                                    <input
                                        type="date"
                                        min={subYears(new Date(), 100).toISOString().split('T')[0]}
                                        max={new Date().toISOString().split('T')[0]}
                                        name="Date Of Birth"
                                        id="dob"
                                        required
                                        className=" col-span-12 md:col-span-9  hover:ring-primary-600 focus:ring-primary-600   bg-primary-50     ring-primary-900/40 placeholder:text-primary-950/60    block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                        placeholder="Date Of Birth"
                                    />
                        </div>
                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="full-name" className="col-span-12 md:col-span-3 text-base ">School
                                Name</label>
                            <input
                                type="text"
                                name="School Name"
                                id="school"
                                required
                                autoComplete="school"
                                placeholder="School Name"
                                className="col-span-12 md:col-span-9 gap-y-4 hover:ring-primary-600 focus:ring-primary-600  bg-primary-50 ring-primary-900/40 placeholder:text-primary-950/60  block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                            />
                        </div>
                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="full-name" className="col-span-12 md:col-span-3 text-base ">Favorite
                                author/artist?</label>
                            <input
                                type="text"
                                name="Favorite Author/Artist"
                                id="favArtist"
                                required
                                placeholder={'Favorite Author/Artist'}
                                className="col-span-12 md:col-span-9 gap-y-4 hover:ring-primary-600 focus:ring-primary-600  bg-primary-50 ring-primary-900/40 placeholder:text-primary-950/60  block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                            />
                        </div>
                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="dob" className="col-span-12 md:col-span-3 text-base "> Biography</label>
                            <textarea
                                name="Biography"
                                id="biography"
                                rows="3"
                                required
                                className=" col-span-12 md:col-span-9 hover:ring-primary-600 focus:ring-primary-600  bg-primary-50     ring-primary-900/40 placeholder:text-primary-950/60    block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                placeholder="Please provide your work history, achievements, publications, grants, awards etc..."></textarea>
                        </div>

                        <div className='grid items-center justify-between gap-y-2 grid-cols-12'>
                            <label htmlFor="dob" className="col-span-12 md:col-span-3 text-base "> Proposed Work</label>
                            <textarea
                                name="Proposed Work"
                                id="proposedWork"
                                rows="3"
                                required
                                className=" col-span-12 md:col-span-9 hover:ring-primary-600 focus:ring-primary-600  bg-primary-50     ring-primary-900/40 placeholder:text-primary-950/60    block w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                placeholder="Please brief us about work you will be doing duing your stay..."></textarea>
                        </div>

                        <div>
                            <p className='text-lg font-bold mb-0 pb-0'>Dates of stay </p>
                            <p className={'mt-0 text-base pt-0'}>Maximum of two weeks of stay.</p>
                        </div>

                        {preferences.map((pref, index) => (
                            <>
                                <div key={index} className='grid items-center justify-between gap-y-2 grid-cols-12'>
                                    <label
                                        className="col-span-12 md:col-span-3 text-base">Preference {index + 1}</label>
                                    <div className="md:col-span-9 col-span-12 flex w-full gap-x-4 gap-y-2 flex-wrap">
                                        <div className='flex-grow'>
                                            <input
                                                type="date"
                                                min={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                value={pref.from}
                                                onChange={(e) => handleDateChange(index, 'from', e.target.value)}
                                                required
                                                className="hover:ring-primary-600 focus:ring-primary-600 bg-primary-50 ring-primary-900/40 w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                                name={`From Date (Preference ${index + 1})`}
                                            />
                                        </div>
                                        <div className='flex-grow'>
                                            <input
                                                type="date"
                                                min={pref.from}
                                                max={pref.from && addWeeks(new Date(pref.from), 2).toISOString().split('T')[0]}
                                                value={pref.to}
                                                onChange={(e) => handleDateChange(index, 'to', e.target.value)}
                                                required
                                                className="hover:ring-primary-600 focus:ring-primary-600 bg-primary-50 ring-primary-900/40 w-full appearance-none rounded-md border-0 px-4 py-4 text-base ring-1 transition focus:outline-none focus:ring-2"
                                                name={`To Date (Preference ${index + 1})`}
                                            />
                                        </div>
                                    </div>


                                </div>
                                {pref.error && (
                                    <div className={'grid items-center justify-between gap-y-2 grid-cols-12'}>
                                        <div
                                            className="col-span-12 md:col-span-3 text-base"></div>
                                        <p className="md:col-span-9 col-span-12 text-red-500 ">{pref.error}</p>
                                    </div>
                                )}
                            </>
                        ))}

                        <p>By submitting this application you accept
                            <a target="_blank"
                               href={PHOTO_WAIVER_LINK}
                               className='font-bold hover:underline'> photo waiver&nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5"
                                     stroke="currentColor" className="inline text-base size-4"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
                                </svg>
                            </a>
                            &nbsp; & &nbsp;
                            <a target="_blank"
                               href={WAIVER_LINK}
                               className='font-bold hover:underline'>general waiver&nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5"
                                     stroke="currentColor" className="inline text-base size-4"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
                                </svg>
                            </a>
                        </p>
                        <div className={'mt-8'}>
                            <ReCAPTCHA
                                sitekey="6LfnB08qAAAAANRvHxBPQ0nj7b10SOqSv6XrkpvJ"
                                onChange={onCaptchaChange} />
                        </div>
                        <div>
                            <p className={'text-red-700'}>{failText}</p>
                            <button
                                disabled={!formValid || buttonText === "Loading..." || buttonText === "Thank you!"}
                                className="bg-primary-600 mt-8  hover:bg-primary-700 focus-visible:outline-primary-600  inline-flex items-center justify-center rounded-full border border-transparent px-12 py-3 text-base font-medium text-white transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                                disabled:bg-primary-200 disabled:text-primary-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed
                                "
                                type="submit"
                            >
                                {buttonText}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>;
}

export default Apply
